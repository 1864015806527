$(document).on("change", "#user_roles_customer_consultancy_create_inquiry, #user_roles_customer_consultancy_document_review, #user_roles_admin_consultancy_consultant, #user_roles_customer_webinar_participate_additional, #user_roles_customer_webinar_participate_exchange, #user_roles_customer_consultancy_participate, #user_roles_customer_webinar_participate, #user_roles_customer_webinar_participate_added_value, #user_roles_customer_general_manage_account", function() {
  if ($(this)[0].checked) {
    $("." + $(this).data("role")).addClass("d-block").removeClass("d-none")
  } else {
    $("." + $(this).data("role")).addClass("d-none").removeClass("d-block")
  }

  // console.log($("#user_roles_customer_webinar_participate_additional")[0].checked)
  // console.log($("#user_roles_customer_webinar_participate")[0].checked)
  // console.log($("#user_roles_customer_webinar_participate_exchange")[0].checked)
  if ($("#user_roles_customer_webinar_participate_additional")[0]?.checked || $("#user_roles_customer_webinar_participate")[0]?.checked || $("#user_roles_customer_webinar_participate_exchange")[0]?.checked || $("#user_roles_customer_webinar_participate_added_value")[0]?.checked) {
    $(".max_webinars_per_month_container").addClass("d-block").removeClass("d-none")
  } else {
    $(".max_webinars_per_month_container").addClass("d-none").removeClass("d-block")
  }

  if ($("#user_roles_customer_webinar_participate_additional")[0]?.checked || $("#user_roles_customer_webinar_participate_exchange")[0]?.checked) {
    $(".webinar_price_container").addClass("d-block").removeClass("d-none")
  } else {
    $(".webinar_price_container").addClass("d-none").removeClass("d-block")
  }

  if ($("#user_roles_customer_general_manage_account")[0]?.checked) {
    $("#user_roles_customer_general_manage_seminars").prop("checked", true);
    $("#user_roles_customer_general_manage_seminars").prop("disabled", true);
    $("#user_roles_customer_general_manage_user").prop("checked", true);
    $("#user_roles_customer_general_manage_user").prop("disabled", true);
  } else {
    $("#user_roles_customer_general_manage_seminars").prop("disabled", false);
    $("#user_roles_customer_general_manage_user").prop("disabled", false);
  }
})

$(document).on("click", ".registration-button", function (event) {
  event.preventDefault()

  $.ajax({
    url: $(this).data("url"),
    type: "POST",
    data: {
      email: $("#user_email").val(),
      password: $("#user_password").val(),
      password_confirmation: $("#user_password_confirmation").val(),
      terms: $("#user_terms").val()
    }
  })
})

$(document).on("click", ".ignore-domain-check", function (event) {
  event.preventDefault()

  $('.modal').modal('show')
  $('.new_user').submit()
})