$(document).on("click", ".packages-payment-interval-tab .nav-link", function(event) {
  event.preventDefault()
  let element = $(this)
  if (element.hasClass("active")) { return }

  let paymentInterval = element.data("paymentInterval")
  $(".payment-interval-selection").val(paymentInterval)
  $(".price").addClass("visibility-none");
  $('.price[data-payment-interval="' + paymentInterval + '"]').removeClass("visibility-none");
  $(".packages-payment-interval-tab .nav-link").removeClass("active")
  element.addClass("active")
})

$(document).on("click", ".packages-tab .nav-link", function(event) {
  event.preventDefault()
  let element = $(this)
  if (element.hasClass("active")) { return }

  let packageId = element.data("packageId")
  $(".package-container").addClass("d-none");
  $('.package-container[data-package-id="' + packageId + '"]').removeClass("d-none");
  $(".packages-tab .nav-link").removeClass("active")
  element.addClass("active")
})

$(document).on("click", ".package-variant-column", function(event) {
  event.preventDefault;
  let parent = $(this).closest("table")
  let id = $(this).data("packageVariantId")
  let active = $(this).hasClass("active")

  if (active) {
    parent.find(".package-variant-column").removeClass("active")
    parent.find(".package-variant-column").removeClass("inactive")
    parent.find(".package-variant-column input.package-variant-id").attr('checked', false)

    let defaultColumns = parent.find(".package-variant-column[data-package-variant-default]")
    if (defaultColumns.length > 0) {
      parent.find(".package-variant-column").addClass("inactive")

      defaultColumns.addClass("active")
      defaultColumns.removeClass("inactive")
      defaultColumns.find("input.package-variant-id").attr('checked', true)
    }
  } else {
    parent.find(".package-variant-column").removeClass("active")
    parent.find(".package-variant-column").addClass("inactive")
    parent.find(".package-variant-column input.package-variant-id").attr('checked', false)
    parent.find(".package-variant-column[data-package-variant-id='" + id + "']").addClass("active")
    parent.find(".package-variant-column[data-package-variant-id='" + id + "']").removeClass("inactive")
    parent.find(".package-variant-column[data-package-variant-id='" + id + "'] input.package-variant-id").attr('checked', true)
  }
})

$(document).on("click", ".tariff_button", function(event) {
  event.preventDefault()

  let packagesCount = $(".packages-tab .nav-link").length
  let currentPackageIndex = $(".packages-tab .nav-link.active").index() + 1

  if (currentPackageIndex < packagesCount) {
    $(".packages-tab .nav-link")[currentPackageIndex].click()
  } else {
    $('.tariff_form').submit()
  }
})