document.addEventListener("turbolinks:load", function() {
  if (!!document.querySelector(".chat")) {
    window.scrollTo(0,document.body.scrollHeight);
  }
})

$(document).on("input", ".chat__message-body-input", function() {
  var maximum = parseInt($(".letter-counter").data("maximum"))
  $('.body-errors').addClass("d-none")
  $(".letter-counter").html(maximum - $(this).val().length)
})

$(document).on("click", ".chat__open, .chat__close", function(event) {
  event.preventDefault()

  $(".chat__closed").toggleClass("d-none")
  $(".chat__opened").toggleClass("d-none")
});
