document.addEventListener(
  "click",
  event => {
    if (
      event.target.matches(
        '[data-behavior="tax-consulting-form"], [data-behavior="tax-consulting-form"] *'
      )
    ) {
      const costCenter = document.getElementById("conversation_cost_center").value;
      const costUnit = document.getElementById("conversation_cost_unit").value;
      let errors = [];

      if (!costCenter.length) {
        errors.push('"Kostenstelle"');
      }

      if (!costUnit.length) {
        errors.push('"Kostenträger"');
      }

      let text = "Um Ihre Anfrage bearbeiten zu können, muss diese vollständig sein. Sind Sie sicher, dass Ihre Angaben vollständig sind und Sie die Anfrage senden möchten?";

      if (errors.length > 0) {
        text += ` Zu beachten: Sie haben ${errors.join(
          " und "
        )} nicht ausgefüllt.`;
      }

      event.preventDefault();

      dataConfirmModal.confirm({
        title: "Anfrage senden",
        text: text,
        commit: "Senden",
        cancel: "Abbrechen",
        zIindex: 10099,
        onConfirm: () => {
          event.target.closest("form").submit();
        }
      });
    }
  },
  false
);
