$(document).on('click', '.xlsx-export', function(event) {
  event.preventDefault();

  const form = $(this).closest('form');
  form.attr('action', $(this).data('new-action'))
  form.attr('remote', false)
  form.submit();

  form.attr('action', $(this).data('old-action'))
  form.attr('remote', true)
});