document.addEventListener("turbolinks:load", () => {
  initializeCountdowns();
});

function initializeCountdowns() {
  document
    .querySelectorAll('[data-behavior="countdown"]')
    .forEach(el => {
      startCountdown(el);
    });
}

function startCountdown(el) {
  let due = parseInt(el.dataset.due);

  setInterval(() => {
    due -= 1;

    const totalSeconds = Math.max(due, 0),
      hours = Math.floor(totalSeconds / 3600),
      minutes = Math.floor((totalSeconds / 60) % 60),
      seconds = totalSeconds % 60;

    el.textContent = [hours, minutes, seconds]
      .map(num => String(num).padStart(2, "0"))
      .join(":");
  }, 1000);
}

global.initializeCountdowns = () => {
  initializeCountdowns()
}
