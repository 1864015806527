$(document).on('input', '.net_price', function() {
  var multiplier = $(this).data('multiplier');
  var value = $(this).val();
  var gross = (value * multiplier).toFixed(2);
  $(this).parents('.row').find('.gross_price').val(gross);
})

$(document).on('input', '.gross_price', function() {
  var netInput = $(this).parents('.row').find('.net_price')
  var multiplier = netInput.data('multiplier');
  var value = $(this).val();
  var net = (value / multiplier).toFixed(2);
  netInput.val(net);
})