$(document).on('click', '.download', function(event) {
  event.preventDefault()

  if ($(this).hasClass("disabled")) { return }

  $(this).attr("disabled")
  $(this).addClass("disabled")
  $(this).prepend("<span class='indicator'><i class='fa fa-spinner fa-spin fa-fw'></span>")

  _this = this
  var request = new XMLHttpRequest();
  request.open("GET", _this.href, true);
  request.responseType = "arraybuffer";

  request.onload = function() {
    var blob = new Blob([request.response], { type: request.getResponseHeader("Content-Type") });
    var name = request.getResponseHeader("Content-Disposition").split('filename="')[1].split("\";")[0]
    var url = window.URL.createObjectURL(blob);

    $('<a></a>').attr({ download: name, href: url })[0].click();

    window.URL.revokeObjectURL(url);

    $(_this).find('.indicator').remove()
    $(_this).removeAttr("disabled")
    $(_this).removeClass("disabled")
  };

  request.send();
})