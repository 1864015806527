import Sortable from 'sortablejs';

document.addEventListener("turbolinks:load", function() {
  $.each($('.sortable'), function(_, element) {
    Sortable.create(element, {
      swapThreshold: 0.65,
      animation: 150,
      draggable: ".draggable",
    	onSort: function (event) {
        $(event.to).find(".draggable").each(function(index) {
          $(this).find("input.position").val(index + 1)
        })
    	},
    })
  })
})
