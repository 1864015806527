$(document).on('click', '.copy-data', function(event) {
  event.preventDefault()

  let element = $(this)
  let currentHtml = element.html()

  navigator.clipboard.writeText(element.data('copy'));


  element.html('<i class="fa fa-check"></i>')
  setTimeout(function() {
    element.html(currentHtml); // Zurücksetzen des Textes nach 2 Sekunden
  }, 1000);
})
